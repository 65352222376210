=<template>
  <div class="container not-found">
    <Branding />
    <h2>{{ contents.not_found }}</h2>
  </div>
</template>

<script>
import contents from '../assets/contents.json'
import Branding from '@/components/Branding.vue'

export default {
  name: 'NoAccess',
  components: {
    Branding,
  },
  setup: () => ({
    contents,
  }),
}
</script>
